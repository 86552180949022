<template>
  <b-container class="main-container-wrapper my-3">
    <router-view />
  </b-container>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style scoped>
</style>
