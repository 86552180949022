import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '@/components/Index.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/mold/:onpreId',
      component: Index,
      props: route => ({
        onpreId: route.params.onpreId
      })
    }
  ]
})

export default router
