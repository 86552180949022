import Axios from 'axios'
// import Store from '../store/index'

const API_END_POINT = process.env.VUE_APP_API_ENDPOINT;
// const API_KEY = process.env.VUE_APP_API_KEY;

Axios.defaults.baseURL = API_END_POINT;

// ここでトークンをセットしたり、無い場合の処理を挟むことができる
Axios.interceptors.request.use(config => {
  config.headers = {
    'Content-Type': 'application/json'
  }
  return config
})

Axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  return Promise.reject(error)
})

const debug = process.env.NODE_ENV !== 'production'

const onSuccess = (res) => {
  if (debug) {
    console.log(' << ' + JSON.stringify(res))
  }
  return Promise.resolve(res)
}
const onError = async (error) => {
  switch (error.response.status) {
    case 401: {
      console.log('ログインが無効です')
      // ログインの有効期限が切れています\nログインしなおしてください
      console.log(error.response.data.detail)
      break
    }
    case 403: {
      console.log('権限がありません')
      console.log(error.response.data.detail)
      break
    }
  }
  return Promise.reject(error)
}

export default {
  get: (url) => {
    if (debug) {
      console.log('GET ' + url)
    }
    return Axios.get(url).then(onSuccess).catch(onError)
  },
  post: (url, payload) => {
    if (debug) {
      console.log('POST ' + url + ' >> ' + JSON.stringify(payload))
    }
    return Axios.post(url, payload).then(onSuccess).catch(onError)
  },
  put: (url, payload) => {
    if (debug) {
      console.log('PUT ' + url + ' >> ' + JSON.stringify(payload))
    }
    return Axios.put(url, payload).then(onSuccess).catch(onError)
  },
  patch: (url, payload) => {
    if (debug) {
      console.log('PATCH ' + url + ' >> ' + JSON.stringify(payload))
    }
    return Axios.patch(url, payload).then(onSuccess).catch(onError)
  },
  delete: (url) => {
    if (debug) {
      console.log('DELETE ' + url)
    }
    return Axios.delete(url).then(onSuccess).catch(onError)
  },
  options: (url) => {
    if (debug) {
      console.log('OPTION ' + url)
    }
    return Axios.options(url).then(onSuccess).catch(onError)
  }
}
