<template>
  <b-col class="moldDetail-wrapper">
    <h5 v-if="isFetching">
      取得中です...
    </h5>
    <h5 v-else-if="isNotFound" style="color: red;">
      エラー：金型データが見つからない または管理外の金型です
    </h5>
    <h5 v-else-if="isDisabled" style="color: red;">
      エラー：管理外の金型です
    </h5>
    <div v-else>
      <h5 v-if="error" class="text-danger">※{{ error }}</h5>
      <b-row class="moldDetail-container">
        <b-col class="th">管理番号</b-col>
        <b-col class="td">{{ moldDetail.onpreId || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">区分</b-col>
        <b-col class="td">{{ moldDetail.category || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">金型コード</b-col>
        <b-col class="td">{{ moldDetail.code || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">金型名</b-col>
        <b-col class="td">{{ moldDetail.name || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">機種名</b-col>
        <b-col class="td">{{ moldDetail.productName || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">型重量</b-col>
        <b-col class="td">{{ moldDetail.weight || '---' }} kg</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">サイズ(幅×高×厚)</b-col>
        <b-col class="td">{{ moldDetail.width || '---' }} × {{ moldDetail.height || '---' }} × {{ moldDetail.depth || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">ｴｼﾞｪｸﾀｽﾄﾛｰｸ<br>MAX値</b-col>
        <b-col class="td">{{ moldDetail.ejector || '---' }} mm</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">プレート</b-col>
        <b-col class="td">{{ moldDetail.plateNum || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">取数</b-col>
        <b-col class="td">{{ moldDetail.caviNum || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">型材CV(キャビ)</b-col>
        <b-col class="td">{{ moldDetail.cavi || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">型材CO(コア)</b-col>
        <b-col class="td">{{ moldDetail.core || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">金型仕様</b-col>
        <b-col class="td">{{ moldDetail.specification || '---' }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">製造年月日</b-col>
        <b-col class="td">{{ moldCreatedAt }}</b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">借用元</b-col>
        <b-col class="td">
          <span v-if="moldDetail.borrowerName">{{ moldDetail.borrowerName }}</span>
          <span v-else>---</span>
        </b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">仕入先</b-col>
        <b-col class="td">
          <span v-if="moldDetail.supplierName">{{ moldDetail.supplierName }}</span>
          <span v-else>---</span>
        </b-col>
      </b-row>
      <b-row class="moldDetail-container">
        <b-col class="th">貸与先</b-col>
        <b-col class="td">
          <span v-if="moldDetail.lenderName">{{ moldDetail.lenderName }}</span>
          <span v-else>---</span>
        </b-col>
      </b-row>
    </div>
  </b-col>
</template>

<script>
import Axios from '@/services/axios.js'

export default {
  name: 'QR-Search',
  title: '読み取り結果',
  data () {
    return {
      moldDetail: { },
      error: '',
      isFetching: false
    }
  },
  computed: {
    moldCreatedAt(){
      if (!this.moldDetail.moldCreated) return '---';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(this.moldDetail.moldCreated).toLocaleDateString('ja-JP', options)
    },
    isNotFound() {
      return (this.moldDetail.id === undefined)
    },
    isDisabled() {
      return (this.moldDetail.isReturned || this.moldDetail.isDiscard)
    }
  },
  props: {
    onpreId: {
      type: String,
      default: null
    }
  },
  methods: {
    async handleFetch () {
      const response = await this.getData();
      const mold = response.data;
      if (response.succeeded === true) {
        this.moldDetail = { ...mold }
      }
    },
    async getData () {
      this.isFetching = true;
      return await Axios.get('Open/Molds/ByOnpreId/' + this.onpreId)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          console.log(err)
          this.error = '取得に失敗しました'
        })
        .finally(() => {
          this.isFetching = false;
        })
    }
  },
  watch: {
    onpreId: {
      handler () {
        this.handleFetch()
      },
      immediate: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.moldDetail-wrapper {
  .moldDetail-container {
    align-items: stretch;
    border: solid 1px rgb(190, 190, 190);
    .th {
      background: rgb(226, 226, 226);
      padding: 8px 0 8px 8px;
    }
    .td {
      align-self: center;
      background: white;
    }
  }
}
</style>
